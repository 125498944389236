/* eslint-disable import/export */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import plugin from './plugin';

export default dayjs
	.extend(LocalizedFormat)
	// @ts-expect-error
	.extend(isSameOrAfter)
	.extend(isSameOrBefore)
	.extend(utc)
	.extend(tz)
	.extend(advancedFormat)
	.extend(plugin) as typeof dayjs;

declare module 'dayjs' {
	// @ts-expect-error
	import type { ManipulateType } from 'dayjs';

	interface Dayjs {
		addInTz(value: number, unit?: ManipulateType): Dayjs;
		subtractInTz(value: number, unit?: ManipulateType): Dayjs;
	}
}

export type { Dayjs } from 'dayjs';

export { tz, isSameOrBefore, isSameOrAfter };
